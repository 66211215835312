import React, { useState } from 'react'
import Layout from '../../components/Layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AboutPage = () => {
    return (<Layout>
        <section className="section">
            <div className="container">
                <div className="content">
                  
                    <h2>Propósito</h2>
                    <p>
                        ReciclApp se esfuerza por promover un sentido de responsabilidad comunitaria y ambiental. 
                        Los buenos hábitos son contagiosos: al facilitar el reciclaje a la población en general e incentivarlo a través de recompensas, la comunidad crece orgánicamente.
                    </p>

                    <h2>Equipo</h2>
                    <p>
                        Somos un equipo diverso de personas con experiencia en el mercado de residuos, I + D industrial aplicado y arquitectura de software, entre otros campos. 
                    </p>

                    <h2>Objetivo</h2>
                    <p>
                    Nuestro objetivo es permitirle crear patrones de consumo sostenibles y reducir su huella de material. 
                    Alineados con los Objetivos de Desarrollo Sostenible de la Agenda de la ONU para 2030, juntos podemos desviar hasta el 62% de los residuos sólidos urbanos de los vertederos en un modelo de economía circular. 
                    Únase a nosotros y tome la iniciativa. Tomar acción.
                    </p>

                    <h2>Premios</h2>

                    <p>
                    Nuestro equipo ha demostrado su excelencia a lo largo del tiempo y ha sido galardonado con múltiples premios por su calidad, innovación y contribución al medio ambiente.
                    </p>
                    <Container>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row><h2 className="centered">Ganadores</h2></Row>
                                <Row><img alt='ganadores' src='/img/awards/winners.png' width="100%" /></Row>
                                <Row><h2 className="centered">Red Directa</h2></Row>
                                <Row><img alt='red directa' src='/img/awards/network.png' width="100%" /></Row>
                                <Row><h2 className="centered">Alcance de Red</h2></Row>
                                <Row><img alt='alcance de red' src='/img/awards/network_reach.png' width="100%" /></Row>
                            </Col>
                            <Col sm={12} md={6}>
                                <Row><h2 className="centered">Finalistas</h2></Row>
                                <Row><img alt='finalistas' src='/img/awards/finalists.png' width="100%" /></Row>
                                
                            </Col>
                        </Row>
                    </Container>
                    <p>
                    Ganadores: Greenpreneurs 2021; Regeneración Ashoka 2022; Climate Finance Accelerator by the UK 2022; The King Hamad Youth Empowerment Award to Achieve SDGs 2022; Microsoft for Startups
                    </p>
                    <p>
                    Finalistas: Heineken Green Challenge 2022; Multi City Challenge 2020; Accelerator 100+ 2022; Santander X Accelerate 2022; Road to Innovate 2022; Climate Launch Pad 2022; Soluciones 2030; Make Sense G9; Extreme Tech Challenge 2022; Eco Reto 2022; Volcano Avenue Startup Summit 2022; Climate Innovation Summit 2022; Oslo Innovation Week 100 2022; Entrepreneurship World Cup 2022
                    </p>
                    <p>
                    Red: GLOBAL HONOR ENTREPRENEURSHIP SOCIETY; Ashoka; GIZ; YABT; Ellen Macarthur Foundation; GGGI;  Friedrich Naumann Foundation for Freedom; BID Lab
                    </p>
                </div>
            </div>
        </section>
    </Layout>

    )
}

export default AboutPage